import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
// import p1 from 'assests/Photos/seva/SewaClass11/1.jpg';
// import p2 from 'assests/Photos/seva/SewaClass11/2.jpg';
// import p3 from 'assests/Photos/seva/SewaClass11/3.jpg';
// import p4 from 'assests/Photos/seva/SewaClass11/4.jpg';
// import p5 from 'assests/Photos/seva/SewaClass11/5.jpg';
// import p6 from 'assests/Photos/seva/SewaClass11/6.jpg';
// import p7 from 'assests/Photos/seva/SewaClass11/7.jpg';
// import p8 from 'assests/Photos/seva/SewaClass11/8.jpg';
// import p9 from 'assests/Photos/seva/SewaClass11/9.jpg';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import { Sidebar2023 } from '../ScintillaOfArt2023/components';


const Grade11Sewa2023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [config, setConfig] = useState({ base_image_archive_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/sewa/SewaClass11/1.webp`;
  const p2 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/sewa/SewaClass11/2.webp`;
  const p3 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/sewa/SewaClass11/3.webp`;
  const p4 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/sewa/SewaClass11/4.webp`;
  const p5 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/sewa/SewaClass11/5.webp`;
  const p6 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/sewa/SewaClass11/6.webp`;
  const p7 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/sewa/SewaClass11/7.webp`;
  const p8 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/sewa/SewaClass11/8.webp`;
  const p9 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/sewa/SewaClass11/9.webp`;
 

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
      src: p5,
      source: p5,
      rows: 1,
      cols: 1,
    },
    {
      src: p6,
      source: p6,
      rows: 1,
      cols: 1,
    },
    {
      src: p7,
      source:p7,
      rows: 1,
      cols: 1,
    },
    {
      src: p8,
      source: p8,
      rows: 1,
      cols: 1,
    },
    {
      src: p9,
      source: p9,
      rows: 1,
      cols: 1,
    },
  ];

  return (
    <Main>
   
<Container>

  <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center' >
      SEWA ACTIVITY
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
      Class: 11	  &  Date: WEDNESDAY, 22 NOVEMBER 2023
      <br></br>
      Place of Visit: Mathru Residential School for the Hearing impaired
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        The students of grade 11 of National Public School, Yeshwanthpur visited Mathru school for the students of Hearing impaired at Yelahanka, for Community Outreach Programme as a part of Social Empowerment through Work Education and Action (SEWA). 
        <br></br>
        The Coordinator Ms Pushpalatha Rao along with the students and teachers distributed stationary and snacks during the visit. Our students organised games, performed Mime act and sung 'Vande Mataram', the national song of India in the sign language. They spent valuable time with those differently abled students of grades 1 to 10 by interacting with them in their language, having snacks together, dancing and playing. 
        <br></br>
        The students participated in this activity with a desire to make a difference, not just to themselves or in their community but also in the world around them.
        <br></br>
        The event was a great learning experience and opportunity for the students. Our students learnt the importance of developing the spirit of giving back to the society, reaching out to the less privileged and feeling grateful for the blessings bestowed upon them.                                                     
        </Typography>
      </Box>
      {/* <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
“Climate change is the Everest of all problems, the thorniest challenge facing humankind - Lewis Gordon Pugh”

<br/>
        </Typography> */}

      <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>

    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <Sidebar2023/>
             </Box>

    </Grid>
    </Grid> 
    </Container>
    </Main>
   
   
  );
};

export default Grade11Sewa2023;

